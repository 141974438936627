﻿import $ from "jquery";
import { Modal } from "bootstrap";
import "../extensions/string-extensions";

var dataLayer = dataLayer || [];

interface UpgradeVoorstel {
    id: number;
    productId: number;
    name: string;
    namePlural: string;
    unitsPerPack: number;
    baseUnit: string;
}

export default class UpgradeOverpack {
    private static modalInstance: Modal;

    private static voorstel: UpgradeVoorstel | null;
    private static voorstelb: number = 0;
    private static newcount: number = 1;
    private static opcount: number = 0;
    private static skipTryOverpack = false;
    private static $opEle = null;
    private static isLast: boolean;
    private static title: string;
    private static rejector: any;
    private static resolver: any;

    static testAskNoMore() {
        if ($("#nt-upgrade-overpack--asknomore").is(":checked")) {
            console.log("Checbox is checked");
            document.cookie = "noopup=1;path=/";
        }
    }

    static acceptOverpack() {
        UpgradeOverpack.testAskNoMore();
        UpgradeOverpack.modalInstance.hide();
        UpgradeOverpack.skipTryOverpack = false;
        UpgradeOverpack.resolver(UpgradeOverpack.newcount);
    }

    static refuseOverpack() {
        UpgradeOverpack.testAskNoMore();
        UpgradeOverpack.modalInstance.hide();
        UpgradeOverpack.skipTryOverpack = false;
        UpgradeOverpack.rejector("User clicked the 'no' button");
    }

    static tryOverpackReal(productId: number, overpackData: UpgradeVoorstel[], value, $ele, last: boolean): Promise<number> {

        return new Promise<number>((resolve, reject) => {

            UpgradeOverpack.resolver = resolve;
            UpgradeOverpack.rejector = reject;

            UpgradeOverpack.$opEle = $ele;
            UpgradeOverpack.isLast = last;
            UpgradeOverpack.voorstel = null;
            UpgradeOverpack.voorstelb = 0;

            for (var i = overpackData.length - 1; i >= 0; i--) {
                var overpack = overpackData[i];

                if ((value * 100) % (overpack.unitsPerPack * 100) === 0) {
                    // aantal is precies een overpack
                    if (UpgradeOverpack.voorstel !== null) {
                        continue;
                    }

                    reject("Quantity is already correct");
                    return;
                }

                var a = (value * 100) / (overpack.unitsPerPack * 100);
                var b = a - Math.floor(a);
                if (b > 0.999 || b < 0.001) {
                    continue;
                }

                if (b < UpgradeOverpack.voorstelb) {
                    continue;
                }

                if (b >= 0.60) {        // 60% 
                    UpgradeOverpack.voorstel = overpack;
                    UpgradeOverpack.voorstelb = b;
                    UpgradeOverpack.opcount = (Math.floor(a) + 1);
                    UpgradeOverpack.newcount = UpgradeOverpack.opcount * overpack.unitsPerPack;
                }
            }

            if (UpgradeOverpack.voorstel !== null) {
                var tmpl = $("#nt-upgrade-overpack--template").html();
                var msg = tmpl;
                var name = UpgradeOverpack.voorstel.name;
                if (UpgradeOverpack.opcount > 1) {
                    name = UpgradeOverpack.voorstel.namePlural;
                }

                msg = msg.replace(/{unit}/gi, UpgradeOverpack.voorstel.baseUnit);
                msg = msg.replace(/{count}/gi, value);
                msg = msg.replace(/{newcount}/gi, UpgradeOverpack.newcount.toString());
                msg = msg.replace(/{opcount}/gi, UpgradeOverpack.opcount.toString());
                msg = msg.replace(/{opname}/gi, name);
                $("#nt-upgrade-overpack--body").html(msg);
            }
            else {
                reject("No need for an upgrade");
                return;
            }

            $("#nt-upgrade-overpack--title").html(UpgradeOverpack.title);
            UpgradeOverpack.modalInstance.show();
        });
    }

    static tryOverpack($container, $input): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            var productId = $container.data("pid");
            var overpackJsonData = $container.data("nt-overpack-json");
            UpgradeOverpack.title = $container.data("nt-product-title");

            if (typeof $input === 'undefined') {
                reject("No $input field found");
                return;
            }

            if (document.cookie.indexOf("noopup=1") !== -1) {
                reject("User doesn't want popups anymore");
                return;
            }

            if (overpackJsonData) {
                const overpackData = overpackJsonData;
                if (overpackData.length > 0) {
                    var val = $input.val();
                    val = parseFloat(val.replace(/,/g, "."));
                    if (val !== "" && parseFloat(val) > 0) {
                        UpgradeOverpack.tryOverpackReal(productId, overpackData, val, $input, true)
                            .then(newQuantity => {
                                resolve(newQuantity)
                            }).catch(reason => {
                                reject(reason);
                            });
                    }
                }
            }
        });
    }

    static init() {
        UpgradeOverpack.modalInstance = new Modal("#nt-upgrade-overpack--modal", { keyboard: false });

        $("#nt-upgrade-overpack--button-ja").on("click", UpgradeOverpack.acceptOverpack);
        $("#nt-upgrade-overpack--button-nee").on("click", UpgradeOverpack.refuseOverpack);
    }
}
